.wrapper {
  position: relative;
}

.input {
  width: 100%;
  border: none;
  background: #e9e9e9;
  color: #303030;
  height: 48px;
  outline: 0;
  outline-offset: 0;
  padding: 12px 16px;
  border-radius: 5px;

  font-family: 'Roboto Reg';
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0;
  transition: opacity 0.3s ease;
}

.input:disabled {
  opacity: 0.5;
}

.input.error {
  background: #fde0e4;
  color: #aa0000;
}

.label {
  font-family: 'Inter Reg';
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0;
}
.label.mobile {
  font-size: 14px;
  line-height: 17px;
}

.errorText {
  position: absolute;
  bottom: -20px;
  color: #dd001e;
  font-family: 'Inter Reg';
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
}
.errorText.mobile {
  bottom: -17px;
  font-size: 12px;
  line-height: 16px;
}
