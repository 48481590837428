.br {
    background: linear-gradient(to right, #2EB0BE, #312372);
    height: 3px;
    width: 100%;
    max-width: 1170px;
    margin: 100px auto 0;
}

.text {
    margin: 50px auto 0;
    padding-bottom: 86px;
    text-align: center;
}