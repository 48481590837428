.h3 {
    margin-top: 14px;
    text-align: center;
    font-family: "Georgia";
    font-size: 24px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0.02em;
}

.wrapper {
    flex: 2;
    border: 1px solid #EFEFEF;
    border-radius: 30px;
    padding: 20px 40px 55px 40px;
    box-shadow: 0 4px 10px 0 #00000040;
    background: #ffffff;
}

.wrapper.mobile {
    border-radius: 10px;
    padding: 13px 19px 16px 19px;
}

.wrapperMain {
    margin: 0 auto 30px;
    max-width: 1200px;
    padding: 20px 15px;
}

.wrapperMain.mobile {
    width: calc(100% - 30px);
    margin: 0 15px 20px;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
}

.p {
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 10px;
}

.p.mobile {
    font-size: 16px;
    line-height: 31px;
    margin-bottom: 10px;
}
.toggle {
    display: flex;
    justify-content: center;
    align-items: center;
}
.description {
    margin-right: 16px;
    cursor: pointer;
    color: #595959;
}
