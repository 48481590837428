.item {
    width: calc(50% - 28px);
}

.item.mobile {
    width: 100%;
    margin-bottom: 15px;
}

.item a {
    display: block;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 20px;
}