.wrapper {
    flex: 2;
    border: 1px solid #EFEFEF;
    border-radius: 30px;
    padding: 20px 40px 55px 40px;
    box-shadow: 0 4px 10px 0 #00000040;
    background: #ffffff;
}

.wrapper.mobile {
    border-radius: 10px;
    padding: 13px 19px 16px 19px;
}

.h3 {
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 8px;
    margin-top: 14px;
}

.h3.mobile {
    margin-top: 10px;
    font-size: 18px;
    line-height: 30px;
}

.p {
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-align: left;
}

.p.mobile {
    font-size: 16px;
    line-height: 31px;
}
