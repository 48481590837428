.wrapper {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.counter {
  width: 100%;
  height: 125px;
  border-radius: 5px;
  background: linear-gradient(273.65deg, #7b56ed -2.5%, #4a9de5 97.48%);
  font-family: 'Roboto Reg';
  font-size: 64px;
  font-weight: 600;
  line-height: 75px;
  letter-spacing: 0.3em;
  text-align: center;
  color: #ffffff;
  padding: 25px 0;
}
.text {
  margin: 18px 0;
  font-family: 'Georgia';
  font-size: 24px;
  font-weight: 400;
  line-height: 37px;
  letter-spacing: 0.02em;
  text-align: center;
}
