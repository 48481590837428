.baner {
    background: linear-gradient(to right, #2EB0BE, #312372);
}

.wrapper {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px;
}

.wrapper.mobile {
    width: calc(100% - 30px);
    margin: 0 15px;
    padding: 12px 0;
}
.formWrapper {
    display: flex;
    justify-content: center;
}

.h1 {
    text-align: left;
    color: #FFFFFF;
    max-width: 580px;

    font-family: "Georgia";
    font-size: 40px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0.02em;
}

.h1.mobile {
    max-width: 80%;
    font-size: 19px;
    line-height: 30px;
}

.img {
    width: 120px;
    height: 120px;
}

.img.mobile {
    width: 49px;
    height: 49px;
}