.main {
    display: flex;
    align-items: flex-end;
    max-width: 1200px;
    margin: 0 auto;
}

.main > * {
    margin: 15px;
}
.main.mobile > * {
    margin: 10px 15px;
}

.lastElem {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.br {
    background: #4D4D4D;
    width: calc(100% - 44px);
    height: 2px;
    margin-left: auto;
    margin-right: auto;
}

.phone {
    font-family: "Roboto Reg";
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0;
    text-align: left;
}

.location {
    font-family: "Inter Reg";
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0;
    text-align: center;
}