.wrapper {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 15px;
}

.wrapper.mobile {
    width: calc(100% - 30px);
    margin: 0 15px;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
}
