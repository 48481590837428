.wrapper h2 {
    font-family: "Georgia";
    font-size: 40px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0;
    margin-bottom: 16px;
}
.wrapper.mobile h2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 12px;
}

.wrapper .phone {
    font-family: "Roboto Reg";
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0;
}
.wrapper.mobile .phone {
    font-size: 16px;
    line-height: 19px;
}

.wrapper p {
    font-family: "Georgia";
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0;
    margin-bottom: 16px;
}
.wrapper.mobile p {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 12px;
}