.baner {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
}

.baner.mobile {
  justify-content: center;
  padding: 0;
  background: linear-gradient(to right, #2eb0be, #312372);
}

.wrapper {
  border: 1px solid #efefef;
  border-radius: 30px;
  padding: 24px 36px 36px 36px;
  box-shadow: 0 4px 10px 0 #00000040;
  background: #ffffff;
  width: calc(66% + 30px);
  margin-top: 28px;
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.wrapper.lg {
  width: 100%;
}

.wrapper.mobile {
  margin-top: 10px;
  margin-bottom: 20px;
  min-width: unset;
  width: calc(100% - 30px);
  border-radius: 10px;
  padding: 10px 16px 16px 16px;
  gap: 21px;
}

.button {
  cursor: pointer;
  width: 100%;
  border: none;
  border-radius: 5px;
  background: #3b91cb;
  box-shadow: 0 4px 4px 0 #00000040;
  color: #ffffff;
  font-family: 'Roboto Reg';
  padding: 13px;

  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.05em;

  text-align: center;

  text-transform: uppercase;
}
.button:disabled {
  cursor: unset;
  box-shadow: none;
  background: #a9a9a9;
}

.button:active {
  box-shadow: none;
}

.endTextWrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px 15px;
}

.endTextWrapper.mobile {
  width: calc(100% - 30px);
  margin: 0 15px;
  padding: 12px 0;
}

.forget {
  display: inline-block;
  cursor: pointer;
  margin: 4px 0 8px 0;
  color: #0d4cd3;
  font-family: 'Inter Reg';
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
}
.forget.mobile {
  margin: 0 0 8px 0;
  font-size: 12px;
  line-height: 16px;
}

.persData {
  margin: 4px 0 8px 0;
  font-family: 'Inter Reg';
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;

  a {
    font: inherit;
  }
}

.persData.mobile {
  margin: 0 0 8px 0;
  font-size: 12px;
  line-height: 16px;
}
