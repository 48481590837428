.wrapper {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px 0;
}

.wrapper.mobile {
    width: calc(100% - 30px);
    padding: 12px 0;
    display: flex;
    flex-direction: column;
}

.h3 {
    margin-top: 14px;
    text-align: center;
    font-family: "Georgia";
    font-size: 24px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0.02em;
}

.circle {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #2F9FB7;
}

.circle.mobile {
    width: 24px;
    height: 24px;
}

.item {
    position: relative;
    width: 25%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashed {
    position: absolute;
    top: 14px;
    left: calc(50% + 16px);
    right: calc(-50% + 16px);
    height: 0;
    border-top: 4px dashed #314B88;
}

.itemVert {
    margin: 0 auto;
    max-width: 280px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashedVert {
    height: 32px;
    border-left: 4px dashed #314B88;
}

.info {
    padding: 20px 10px 8px 10px;
}

